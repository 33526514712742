import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Gallery from "../components/productDetail/gallery";
import NewsLetters from "../components/common/newsletter";
import { StoreContext } from "../context/storeContext";
import CartPopup from "../components/common/popup/Cart";
import RelatedProducts from "../components/relatedProducts";
import { getProductSellingPlans } from "../api/query";
import Seo from "../components/common/SEO";

const productVariant = {
  oneTime: "ONE_TIME_PURCHASE",
};

export default function IndexPage({ data: { shopifyProduct } }) {
  const { addVariantToCart, addedToCart, setAddedToCart, checkout } =
    React.useContext(StoreContext);
  const [quantity, setQuantity] = React.useState(1);
  const [show, setShow] = useState(false);
  const [variants, setVariants] = React.useState([]);
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [featuredImage, setFeaturedImage] = useState(null);
  const [sellingPlanGroup, setSellingPlanGroup] = React.useState(null);
  const [selectedPlan, setSelectedPlan] = React.useState(
    productVariant.oneTime
  );
  const [subscriptionId, setSubscriptionId] = React.useState("");

  useEffect(() => {
    // setFeaturedImage(
    //   shopifyProduct?.featuredMedia?.preview?.image?.gatsbyImageData
    // );
    const variantList = shopifyProduct?.variants?.filter(
      (item) => item.title !== "Default Title"
    );
    setVariants(variantList);
    getSellingPlan();
  }, [shopifyProduct]);

  useEffect(() => {
    if (sellingPlanGroup && selectedPlan !== productVariant.oneTime) {
      setSubscriptionId(
        sellingPlanGroup[0]?.node?.sellingPlans?.edges[0]?.node?.id
      );
    }
  }, [selectedPlan]);

  const getSellingPlan = async () => {
    const response = await getProductSellingPlans(shopifyProduct?.handle);
    setSellingPlanGroup(response);
  };

  const increaseQuantity = () => {
    setQuantity(quantity + 1);
  };
  const decreaseQuantity = () => {
    if (quantity !== 1) {
      setQuantity(quantity - 1);
    }
  };

  useEffect(() => {
    if (addedToCart) {
      setShow(true);
    }
  }, [addedToCart]);

  const hidePopup = () => {
    setAddedToCart(false);
    setShow(false);
  }

  return (
    <Layout>
      <Seo
        title={shopifyProduct?.seo?.title || shopifyProduct?.title}
        description={
          shopifyProduct?.seo?.description || shopifyProduct?.description
        }
        ogImage={shopifyProduct?.featuredMedia?.preview?.image?.src}
      />
      <div className="pt-[57px]">
        <div className="mix-p-container">
          <div className="flex flex-col items-center w-full px-6 lg:flex-row xl:px-0 lg:items-start">
            <div className="w-full lg:max-w-[56%]">
              <div className="flex flex-col-reverse items-center xl:flex-row xl:items-start">
                <Gallery
                  images={
                    shopifyProduct?.media.length > 0
                      ? shopifyProduct?.media
                      : []
                  }
                  setImage={setFeaturedImage}
                />
                <div className="pl-[10px] w-full flex justify-center">
                  <GatsbyImage
                    image={
                      featuredImage
                        ? featuredImage
                        : shopifyProduct?.featuredMedia?.preview?.image
                            ?.gatsbyImageData
                    }
                    alt={shopifyProduct?.title}
                    objectFit="fill"
                  />
                </div>
              </div>
            </div>
            <div className="w-full lg:max-w-[44%] lg:pl-5 xl:pl-[60px] pt-6 lg:pt-0">
              <div className="flex flex-col items-center h-full lg:items-start">
                <div>
                  <div className="pb-2 mx-auto border-b-2 border-black lg:mx-0 max-w-max">
                    <h1 className="text-[25px] leading-common font-normal">
                      {shopifyProduct?.title}
                    </h1>
                  </div>
                  <div className="pt-[5px] text-center lg:text-left">
                    <p className="text-md">{shopifyProduct?.description}</p>
                  </div>
                  {variants.length > 0 && (
                    <div className="flex flex-col items-center mt-8 sm:items-start">
                      {/* <label className="text-[20px] leading-common font-normal">
                        Sizes
                      </label> */}
                      <div className="flex flex-wrap">
                        {variants.map((item) => (
                          <div
                            key={item.title}
                            className={`border-[1.5px] px-5 py-2 mr-4 cursor-pointer mb-4 ${
                              !item?.availableForSale
                                ? "border-[#AFAFAF]"
                                : "border-black"
                            } ${
                              item.storefrontId ===
                                selectedOption?.storefrontId && "bg-black"
                            }`}
                            onClick={() => setSelectedOption(item)}
                          >
                            <span
                              className={`text-[11px] leading-common font-medium tracking-[0.21em] ${
                                !item?.availableForSale
                                  ? "text-[#AFAFAF]"
                                  : "text-black"
                              } ${
                                item.storefrontId ===
                                  selectedOption?.storefrontId && "text-white"
                              }`}
                            >
                              {item.title}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  <div className="mt-8">
                    <label className="text-[20px] leading-common font-normal">
                      Quantity
                    </label>
                    <div className="mt-[13px]">
                      <div className="border-[1.5px] border-black h-[42px] w-[89px] flex">
                        <div className="w-[65%] flex justify-center items-center">
                          <input
                            className="text-md font-medium tracking-[0.21em] w-8 text-center outline-none"
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                          />
                        </div>
                        <div className="flex flex-col w-[35%] h-full">
                          <button
                            className="flex items-center justify-center cursor-pointer h-1/2"
                            onClick={increaseQuantity}
                          >
                            <svg
                              width="14"
                              height="8"
                              viewBox="0 0 14 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M1 7L7 1L13 7" stroke="black" />
                            </svg>
                          </button>
                          <button
                            className="flex items-center justify-center cursor-pointer h-1/2"
                            onClick={decreaseQuantity}
                          >
                            <svg
                              width="14"
                              height="8"
                              viewBox="0 0 14 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13 0.5L7 6.5L1 0.499999"
                                stroke="black"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {sellingPlanGroup && sellingPlanGroup?.length > 0 && (
                  <div className="flex flex-col w-full max-w-[285px] border border-black mt-10">
                    <div className="py-[10px] px-[10px]">
                      <input
                        id="r1"
                        type="radio"
                        name="radio"
                        value={productVariant.oneTime}
                        onChange={() => {
                          setSubscriptionId("");
                          setSelectedPlan(productVariant.oneTime);
                        }}
                        checked={selectedPlan === productVariant.oneTime}
                      />
                      <label htmlFor="r1">One Time Purchase</label>
                    </div>
                    {sellingPlanGroup &&
                      sellingPlanGroup?.map(({ node }) => (
                        <div
                          className="border-t border-black py-[10px] px-[10px]"
                          key={node?.name}
                        >
                          <input
                            id="r2"
                            type="radio"
                            name="radio"
                            value={node?.name}
                            onChange={() => setSelectedPlan(node?.name)}
                            checked={selectedPlan === node?.name}
                          />
                          <label htmlFor="r2" className="capitalize">
                            {node?.name}
                          </label>
                          {node?.sellingPlans?.edges.length > 0 &&
                            selectedPlan === node?.name &&
                            node?.sellingPlans?.edges.map((item, index) => (
                              <div className="pt-3 pl-5" key={item?.node?.id}>
                                <input
                                  id={`rc${index}`}
                                  type="radio"
                                  name={`option${index}`}
                                  value={item?.node?.id}
                                  onChange={() =>
                                    setSubscriptionId(item?.node?.id)
                                  }
                                  checked={subscriptionId === item?.node?.id}
                                />
                                <label
                                  htmlFor={`rc${index}`}
                                  className="capitalize"
                                >
                                  {item?.node?.options[0].name}{" "}
                                  {item?.node?.options[0].value}
                                </label>
                              </div>
                            ))}
                        </div>
                      ))}
                  </div>
                )}
                {variants.length > 0 ? (
                  <div className="flex flex-col items-center mt-10 sm:items-start">
                    <button
                      className="py-[10px] px-14 bg-[#2C2C2C] text-white text-md font-medium tracking-[0.21em]"
                      onClick={() =>
                        addVariantToCart(
                          selectedOption?.storefrontId,
                          quantity,
                          subscriptionId
                        )
                      }
                      disabled={!selectedOption}
                    >
                      Add to Cart{" "}
                      {selectedOption && "- $" + selectedOption?.price}
                    </button>
                  </div>
                ) : (
                  <div className="flex flex-col items-center mt-10 sm:items-start">
                    <button
                      className="py-[10px] px-14 bg-[#2C2C2C] text-white text-md font-medium tracking-[0.21em]"
                      onClick={() =>
                        addVariantToCart(
                          shopifyProduct?.variants[0]?.storefrontId,
                          quantity,
                          subscriptionId
                        )
                      }
                    >
                      Add to Cart - ${shopifyProduct?.variants[0]?.price}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-[111px]">
          <RelatedProducts productId={shopifyProduct?.storefrontId} merch />
        </div>
        <div className="mt-[86px]">
          <NewsLetters small={true} />
        </div>
      </div>
      {checkout?.lines?.edges.length > 0 && show && (
        <CartPopup list={checkout.lines?.edges} onClick={hidePopup} />
      )}
    </Layout>
  );
}

export const query = graphql`
  query ($productId: String!) {
    shopifyProduct(id: { eq: $productId }) {
      title
      shopifyId
      description
      priceRangeV2 {
        maxVariantPrice {
          amount
        }
        minVariantPrice {
          amount
        }
      }
      status
      mediaCount
      media {
        preview {
          image {
            gatsbyImageData(
              placeholder: DOMINANT_COLOR
              formats: WEBP
              width: 530
              height: 719
            )
          }
        }
      }
      onlineStoreUrl
      onlineStorePreviewUrl
      storefrontId
      id
      featuredMedia {
        preview {
          image {
            src
            gatsbyImageData(
              placeholder: DOMINANT_COLOR
              formats: WEBP
              width: 530
              height: 719
            )
          }
        }
      }
      variants {
        id
        availableForSale
        displayName
        title
        price
        storefrontId
        media {
          preview {
            image {
              gatsbyImageData
            }
          }
        }
      }
      seo {
        title
        description
      }
    }
  }
`;
